import { APP_NAME, isDebug, requestAnimFrame, $html, $window } from '../utils/environment';
import AbstractModule from './AbstractModule';

const MODULE_NAME = 'Example';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);

        // Declaration of properties
        // ==========================================================================
        if(isDebug) console.log('🔨 [module]:constructor - '+MODULE_NAME);
        this.$replayButton  = this.$el.find('.js-replay');
        this.$container     = this.$el.find('.js-animation-wrapper');
    }

    init() {
        var animTime            = null,
            animCounter         = 0,
            $animation          = $('.js-animation'),
            $barrel             = $animation.find('#Lily-Product'),
            $splash             = $barrel.find('.-splash'),
            $bubbles            = $barrel.find('#Bubbles'),
            $question           = $('.js-question'),
            $mobileSection      = $('.js-mobile-section'),
            isIE                = window.navigator.msPointerEnabled,
            userSplash          = false,
            splashDirty         = false,
            splashTime          = 2.25,
            dirtySplashTimer    = splashTime,
            animDebug           = false,
            questionTime        = 0,
            questionVisible     = false,
            timeOffsets         = {
                'knowledge':    1,
                'data':         6.5,
                'solutions':    12,
                'finally':      18.5
            },
            baseAnimTracker     = {             // Timing is in seconds
                'knowledge': {
                    'id':               'knowledge',
                    'el':               $animation.find('#Chemicals #Knowledge'),
                    'copyEl':           $animation.find('#Whiteboard #Copy #Knowledge'),
                    'startTime':        timeOffsets.knowledge,
                    'bubbleTime':       1 + timeOffsets.knowledge,
                    'startMoving':      2 + timeOffsets.knowledge,
                    'idleTime':         3 + timeOffsets.knowledge,
                    'copyTime':         2.25 + timeOffsets.knowledge,
                    'dropTime':         4 + timeOffsets.knowledge,
                    'copyVisible':      false,
                    'isMoving':         false,
                    'isIdle':           false,
                    'isAnimating':      false,
                    'bubbleVisible':    false,
                    'handVisible':      false,
                    'isOverBarrel':     false,
                    'isInBarrel':       false,
                },
                'data': {
                    'id':               'data',
                    'el':               $animation.find('#Chemicals #Data'),
                    'copyEl':           $animation.find('#Whiteboard #Copy #Data'),
                    'startTime':        timeOffsets.data,
                    'bubbleTime':       1 + timeOffsets.data,
                    'startMoving':      2 + timeOffsets.data,
                    'idleTime':         3 + timeOffsets.data,
                    'copyTime':         2.25 + timeOffsets.data,
                    'dropTime':         4 + timeOffsets.data,
                    'copyVisible':      false,
                    'isMoving':         false,
                    'isIdle':           false,
                    'isAnimating':      false,
                    'bubbleVisible':    false,
                    'handVisible':      false,
                    'isOverBarrel':     false,
                    'isInBarrel':       false,
                },
                'solutions': {
                    'id':               'solutions',
                    'el':               $animation.find('#Chemicals #Solutions'),
                    'copyEl':           $animation.find('#Whiteboard #Copy #Solutions'),
                    'startTime':        timeOffsets.solutions,
                    'bubbleTime':       1 + timeOffsets.solutions,
                    'startMoving':      2 + timeOffsets.solutions,
                    'idleTime':         3 + timeOffsets.solutions,
                    'copyTime':         2.25 + timeOffsets.solutions,
                    'dropTime':         4 + timeOffsets.solutions,
                    'copyVisible':      false,
                    'isMoving':         false,
                    'isIdle':           false,
                    'isAnimating':      false,
                    'bubbleVisible':    false,
                    'handVisible':      false,
                    'isOverBarrel':     false,
                    'isInBarrel':       false,
                },
                'finally':{
                    'bubbleEl':         $barrel.find('#Chat-Bubble'),
                    'startTime':        timeOffsets.finally,
                    'bubbleTime':       timeOffsets.finally,
                    'isFinished':       false,
                    'isAnimating':      false,
                    'bubbleVisible':    false
                }
            },
            animTracker     = {             // Timing is in seconds
                'knowledge': {
                    'id':               'knowledge',
                    'el':               $animation.find('#Chemicals #Knowledge'),
                    'copyEl':           $animation.find('#Whiteboard #Copy #Knowledge'),
                    'startTime':        timeOffsets.knowledge,
                    'bubbleTime':       1 + timeOffsets.knowledge,
                    'startMoving':      2 + timeOffsets.knowledge,
                    'idleTime':         3 + timeOffsets.knowledge,
                    'copyTime':         2.25 + timeOffsets.knowledge,
                    'dropTime':         4 + timeOffsets.knowledge,
                    'copyVisible':      false,
                    'isMoving':         false,
                    'isIdle':           false,
                    'isAnimating':      false,
                    'bubbleVisible':    false,
                    'handVisible':      false,
                    'isOverBarrel':     false,
                    'isInBarrel':       false,
                },
                'data': {
                    'id':               'data',
                    'el':               $animation.find('#Chemicals #Data'),
                    'copyEl':           $animation.find('#Whiteboard #Copy #Data'),
                    'startTime':        timeOffsets.data,
                    'bubbleTime':       1 + timeOffsets.data,
                    'startMoving':      2 + timeOffsets.data,
                    'idleTime':         3 + timeOffsets.data,
                    'copyTime':         2.25 + timeOffsets.data,
                    'dropTime':         4 + timeOffsets.data,
                    'copyVisible':      false,
                    'isMoving':         false,
                    'isIdle':           false,
                    'isAnimating':      false,
                    'bubbleVisible':    false,
                    'handVisible':      false,
                    'isOverBarrel':     false,
                    'isInBarrel':       false,
                },
                'solutions': {
                    'id':               'solutions',
                    'el':               $animation.find('#Chemicals #Solutions'),
                    'copyEl':           $animation.find('#Whiteboard #Copy #Solutions'),
                    'startTime':        timeOffsets.solutions,
                    'bubbleTime':       1 + timeOffsets.solutions,
                    'startMoving':      2 + timeOffsets.solutions,
                    'idleTime':         3 + timeOffsets.solutions,
                    'copyTime':         2.25 + timeOffsets.solutions,
                    'dropTime':         4 + timeOffsets.solutions,
                    'copyVisible':      false,
                    'isMoving':         false,
                    'isIdle':           false,
                    'isAnimating':      false,
                    'bubbleVisible':    false,
                    'handVisible':      false,
                    'isOverBarrel':     false,
                    'isInBarrel':       false,
                },
                'finally':{
                    'bubbleEl':         $barrel.find('#Chat-Bubble'),
                    'startTime':        timeOffsets.finally,
                    'bubbleTime':       timeOffsets.finally,
                    'isFinished':       false,
                    'isAnimating':      false,
                    'bubbleVisible':    false
                }
            };

        // animationWrangler handles the animations
        // it hides/displays elements, manages elements CSS, toggles classes, and CSS animations
        // @param {Object} animation - animTracker.x
        let animationWrangler = (animation)=>{
            if(!animation.isAnimating){
                if(animDebug){
                    console.log('%c =============================================', 'color: #87ff87');
                    console.log('%c Starting New Animation Segment', 'color: #87ff87');
                    console.log('%c =============================================', 'color: #87ff87');
                    console.log(animation);
                }
                $splash.removeClass('-visible -knowledge -data -solutions');
                animation.isAnimating = true;
            }else{
                if(animCounter >= animation.bubbleTime && !animation.bubbleVisible && !animation.isMoving){
                    animation.el.find('#Chat-Bubble').addClass('-visible');
                    animation.bubbleVisible = true;
                    if(animDebug) console.log('%c >Chat Bubble Appeared', 'color: #ffac6f');
                }

                if(animCounter >= animation.startMoving && animation.bubbleVisible && !animation.handVisible){
                    animation.el.find('#Hand').addClass('-visible');
                    animation.handVisible = true;
                    if(animDebug) console.log('%c >Hand Appeared', 'color: #ffac6f');

                    animation.el.addClass('-moving');
                    animation.isMoving = true;
                    if(animDebug) console.log('%c >Chemical Is Moving', 'color: #ffac6f');
                }

                if(animCounter >= animation.idleTime && !animation.isIdle && animation.isMoving){
                    animation.isOverBarrel = true;
                    animation.isMoving = false;
                    animation.isIdle = true;
                    animation.el.removeClass('-moving');
                    animation.el.addClass('-idling');
                    $bubbles.removeClass('-visible  -knowledge -data -solutions');
                    if(animDebug) console.log('%c >Chemical Is Idle Over Barrel', 'color: #ffac6f');
                }

                if(animCounter >= animation.copyTime && !animation.copyVisible){
                    animation.copyVisible = true;
                    animation.copyEl.addClass('-visible');
                    if(animDebug) console.log('%c >Related Copy Appeared', 'color: #ffac6f');
                }

                if(animCounter >= animation.dropTime && !animation.isMoving && animation.copyVisible){
                    animation.isOverBarrel = false;
                    animation.isInBarrel = true;
                    animation.bubbleVisible = false;
                    animation.handVisible = false;
                    animation.el.find('#Hand').removeClass('-visible');
                    animation.el.find('#Chat-Bubble').removeClass('-visible');
                    animation.el.addClass('-dropping').removeClass('-idling');
                    $splash.addClass('-visible -'+animation.id);
                    $bubbles.addClass('-visible -'+animation.id);
                    if(animDebug) console.log('%c >Dropped Chemical', 'color: #ffac6f');
                    if(animDebug) console.log('%c Segment Is Finished', 'color: #ff6f6f');
                    animation.isAnimating = false;
                }
            }
        }

        // animationFinally displays the final few SVG animations
        // it hides/displays elements, manages elements CSS, toggles classes, and CSS animations
        // @param {Object} animation - animTracker.x
        let animationFinally = (animation)=>{
            if(!animation.isAnimating){
                if(animDebug){
                    console.log('%c =============================================', 'color: #87ff87');
                    console.log('%c Starting Final Animation Segment', 'color: #87ff87');
                    console.log('%c =============================================', 'color: #87ff87');
                    console.log(animation);
                }
                animation.isAnimating = true;
            }else{        
                if(animCounter >= animation.bubbleTime && !animation.bubbleVisible){
                    animation.bubbleVisible = true;
                    animation.bubbleEl.addClass('-visible');
                    animation.isFinished = true;
                    $question.addClass('-answer');
                    if(animDebug) console.log('%c >Barrel Bubble Appeared', 'color: #ffac6f');
                    if(animDebug) console.log('%c Segment Is Finished', 'color: #ff6f6f');
                    animation.isAnimating = false;
                    $splash.removeClass('-visible -user -knowledge -data -solutions');
                    $bubbles.removeClass('-visible -user  -knowledge -data -solutions');
                }
            }
            this.$replayButton.fadeIn();
        }

        // animationLoop is called during the DOM repaint by requesting the browsers animation frame
        // we use deltaTime to control our animations timing
        // @source https://developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame
        let animationLoop = ()=>{
            let timeNew = Date.now();
            let deltaTime = (timeNew - animTime) / 1000;
            animTime = timeNew;

            animCounter += deltaTime;

            if(animCounter >= questionTime && !questionVisible){
                $question.addClass('-question');
            }

            if(animCounter >= animTracker.knowledge.startTime && !animTracker.knowledge.isInBarrel){
                animationWrangler(animTracker.knowledge);
            }

            if(animCounter >= animTracker.data.startTime && !animTracker.data.isInBarrel){
                animationWrangler(animTracker.data);
            }

            if(animCounter >= animTracker.solutions.startTime && !animTracker.solutions.isInBarrel){
                animationWrangler(animTracker.solutions);
            }

            if(animCounter >= animTracker.finally.startTime && !animTracker.finally.isFinished){
                animationFinally(animTracker.finally);
            }

            if(splashDirty){
                dirtySplashTimer -= deltaTime;

                if(dirtySplashTimer <= 0){
                    splashDirty = false;
                    userSplash = false;
                    $splash.removeClass('-visible -user');
                    $bubbles.removeClass('-visible -user');
                    dirtySplashTimer = splashTime;
                }
            }

            if(userSplash && !splashDirty){
                $splash.addClass('-visible -user');
                $bubbles.addClass('-visible -user');
                splashDirty = true;
            }

            requestAnimFrame(animationLoop);
        }

        // Starts animation when the SVG is within the users view
        // Uses scrollex jQuery libary
        // @source https://github.com/ajlkn/jquery.scrollex
        $animation.scrollex({
            top: '25vh',
            enter: ()=>{
                if(animDebug) console.log('%c >SVG Entered Users View', 'color: #ffac6f');
                if(window.innerWidth >= 1024 && !isIE && navigator.userAgent.indexOf('Edge') < 0){
                    animTime = Date.now();
                    animationLoop();
                }
            }
        });

        $mobileSection.scrollex({
            top: '-100%',
            bottom: '-100%', 
            mode: 'middle',
            enter: function(){
                $(this).addClass('-visible');
            },
            leave: function(){
                $(this).removeClass('-visible');
            }
        });

        $barrel.on('click', function(){
            if(!animTracker.knowledge.isAnimating && !animTracker.data.isAnimating && !animTracker.solutions.isAnimating && !animTracker.finally.isAnimating) userSplash = true;
        });

        let replayAnimation = ()=>{
            $.get('/ajax/animation', (payload)=>{
                this.$container.html(payload);
                $question.removeClass('-answer -question');
                this.$replayButton.fadeOut();
                this.init();
            });
        };

        this.$replayButton.on('click', ()=>{
            replayAnimation();
        });

        (()=>{
            let isIE = window.navigator.msPointerEnabled;
            let isEdge = '-ms-scroll-limit' in document.documentElement.style && '-ms-ime-align' in document.documentElement.style && !window.navigator.msPointerEnabled;
            if(isIE || isEdge){
                $('.js-video').scrollex({
                    enter: ()=>{
                        if($('.js-video')[0] !== null && $('.js-video')[0] !== undefined) $('.js-video')[0].play();
                    }
                });
            }else{
                $html.animate({scrollTop: ($window.scrollTop() + 1) }, 0);
            }
        })();
    }

    destroy() {
        // Removing event listeners
        // ==========================================================================
        this.$replayButton.off('click');
        super.destroy(isDebug, MODULE_NAME, EVENT_NAMESPACE);
    }
}