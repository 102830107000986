import { APP_NAME, isDebug } from '../utils/environment';
import AbstractModule from './AbstractModule';

const MODULE_NAME = 'Example';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);

        // Declaration of properties
        // ==========================================================================
        if(isDebug) console.log('🔨 [module]:constructor - '+MODULE_NAME);
    }

    init() {
        // Declaration of functions
        // ==========================================================================
        
        // Declaration of event listeners
        // ==========================================================================
    }

    destroy() {
        // Removing event listeners
        // ==========================================================================
        super.destroy(isDebug, MODULE_NAME, EVENT_NAMESPACE);
    }
}