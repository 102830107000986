import { APP_NAME, isDebug, $html, $window } from '../utils/environment';
import AbstractModule from './AbstractModule';

const MODULE_NAME = 'Products';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);

        // Declaration of properties
        // ==========================================================================
        if(isDebug) console.log('🔨 [module]:constructor - '+MODULE_NAME);
        
        // jQuery Objects
        this.$loading           = $('.js-loading-icon');
        this.$menu              = $('.js-product-filter-menu');
        this.$icon              = $('.js-filter-icon');
        this.$orderBtn          = $('.js-order-button');
        this.$helper            = $('.js-filter-helper');
        this.$container         = $('.js-product-container');
        this.$products          = this.$container.children();
        this.$loadBtn           = $('.js-load-more');
        this.$header            = $('.js-content-header');
        this.$loadingContainer  = $('.js-load-more-container');
        this.$catFilters        = $('.js-product-filter');

        // Class vars
        this.total      = $(this.$products[0]).data('total');
        this.page       = 1;
        this.doingAjax  = false;
        this.allowAjax  = true;
        this.count      = this.$container.children('.js-product').length;
        this.activeCat  = null;
    }

    init() {
        // Declaration of functions
        // ==========================================================================


        // cleanAjaxStatus checks if there are still more entries to load if not we won't allow ajaxing
        // also cleans the doingAjax status
        let cleanAjaxStatus = ()=>{
            if(this.count < this.total) this.allowAjax = true;
            else this.allowAjax = false;
            this.doingAjax = false;

            if(!this.allowAjax){
                this.$loadingContainer.fadeOut();
            }
            else{
                this.$loadingContainer.fadeIn();
            }
        }

        // initializeScrollex scrolls the page 1px so the initial row of products
        // that we load will appear
        let initializeScrollex = ()=>{
            $html.animate({scrollTop: ($window.scrollTop() + 1) }, 0);
        }

        // updateProductsList uses jQuery to get a reference to all of our prodcuts
        // so we can attach the scrollex scroll event listener to them for our
        // display effects
        let updateProductsList = ()=>{
            this.$products = $('.js-product');

            // Use scrollex to display products when they enter the screen initially
            // @source https://github.com/ajlkn/jquery.scrollex
            this.$products.scrollex({
                top: '25%',
                enter: function(){
                    $(this).addClass('u-visible');
                }
            });

            initializeScrollex();
            cleanAjaxStatus();
        }

        let updatePushState = ()=>{
            let order = getOrder();

            if(this.activeCat !== null){
                if(order === 'title'){
                    history.pushState({}, null, "/products/"+this.activeCat);
                }else{
                    history.pushState({}, null, "/products/"+this.activeCat+"?d="+order);
                }
            }else{
                history.pushState({}, null, "?d="+order);
            }
        }

        // ajaxNewEntries resets our page and count so we can replace all the current
        // product entries with the entries that fit our filter settings
        // we scroll the user back to the top of the page (minus the header area)
        // before loading in our new html
        // @param { order : string }
        // @param { cat : string }
        let ajaxNewEntries = (order = 'title', cat = null)=>{
            if(this.doingAjax) return;

            this.doingAjax = true;
            this.$loading.addClass('u-visible');
            this.page = 1;
            this.count = 21;
            $html.animate({scrollTop: this.$header[0].scrollHeight }, 1000);
            this.$menu.removeClass('u-visible');
            this.$products.each(function(){
                $(this).addClass('u-hidden');
            });

            let params = '?';
            params += 'd=' + order;

            if(cat !== null){
                params += '&c=' + cat;
            }else if(this.activeCat !== null){
                params += '&c=' + this.activeCat;
            }

            if(cat !== null){
                this.activeCat = cat;
            }

            $.get('/ajax/products/p' + this.page + params, (payload)=>{
                this.$loading.removeClass('u-visible');
                let newHtml = $(payload);

                // Updates our new total number of products
                this.total = $(newHtml[0]).data('total');
                
                setTimeout((newHtml)=>{
                    this.$container.html(newHtml);
                    updateProductsList();
                    updatePushState();
                }, 1000, newHtml);
            });
        }

        let ajaxAdditionalEntries = (order = 'title')=>{
            if(this.doingAjax || !this.allowAjax) return;
            
            this.doingAjax = true;
            this.$loading.addClass('u-visible');
            this.page++;
            this.count += 21;

            let params = '?';
            params += 'd=' + order;

            if(this.activeCat !== null){
                params += '&c=' + this.activeCat
            }

            $.get('/ajax/products/p' + this.page + params, (payload)=>{
                this.$loading.removeClass('u-visible');
                let newHtml = $(payload);
                this.$container.append(newHtml);
                updateProductsList();
            });
        }

        // IIFE for getting initial products
        (()=>{
            updateProductsList();
        })();

        // getOrder returns the status of the order button
        // @returns { order : string }
        let getOrder = ()=>{
            let order = 'title';
            if(this.$orderBtn.hasClass('-flipped')) order = 'title-desc';
            return order;
        }

        // Declaration of event listeners
        // ==========================================================================
        
        this.$catFilters.on('click', function(){
            let cat = $(this).data('slug');
            let order = getOrder();
            ajaxNewEntries(order, cat);
        });

        // User is trying to open/close the filter menu
        this.$icon.on('click', ()=>{
            this.$menu.toggleClass('u-visible');
            $html.addClass('product-filter-acknowledged');
        });

        // User wants to flip the direction of the products
        this.$orderBtn.on('click', ()=>{
            this.$orderBtn.toggleClass('-flipped');
            ajaxNewEntries(getOrder());
        });

        // We set a custom status so we don't span the user with the filter help message
        this.$helper.on('click', ()=>{
            $html.addClass('product-filter-acknowledged');
        });

        // User wants to ajax in more products
        this.$loadBtn.on('click', ()=>{
            if(this.$orderBtn.hasClass('-flipped')){
                ajaxAdditionalEntries('title-desc');
            }else{
                ajaxAdditionalEntries();
            }
        })
    }

    destroy() {
        // Removing event listeners
        // ==========================================================================
        this.$icon.off('click');
        this.$orderBtn.off('click');
        this.$helper.off('click');
        this.$loadBtn.off('click');
        super.destroy(isDebug, MODULE_NAME, EVENT_NAMESPACE);
    }
}