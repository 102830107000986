import { APP_NAME, isDebug } from '../utils/environment';
import AbstractModule from './AbstractModule';

const MODULE_NAME = 'Visibility';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);

        // Declaration of properties
        // ==========================================================================
        if(isDebug) console.log('🔨 [module]:constructor - '+MODULE_NAME);
        this.$elements  = this.$el.find('.js-visible');
        this.type       = (this.$el.data('type') === undefined) ? 'default' : this.$el.data('type');
    }

    init() {
        // Declaration of functions
        // ==========================================================================
        
        // Declaration of event listeners
        // ==========================================================================
        
        // Style elements as then enter the center of the users view
        // @source https://github.com/ajlkn/jquery.scrollex
        if(this.$elements.length !== 0){
            if(this.type === 'default'){
                this.$elements.scrollex({
                    top: '-10%',
                    bottom: '-10%',
                    mode: 'middle',
                    enter: function(){
                        $(this).addClass('u-visible');
                    },
                    leave: function(){
                        $(this).removeClass('u-visible');
                    },
                });
            }
            else if(this.type === 'initial'){
                this.$elements.scrollex({
                    top: '50%',
                    enter: function(){
                        $(this).addClass('u-visible');
                    }
                });
            }
        }
        else if(this.type === 'products'){
            this.$elements = this.$el.find('.js-product');
            this.$elements.scrollex({
                top: '25%',
                enter: function(){
                    $(this).addClass('u-visible');
                }
            });
        }
        else{
            if(this.$el.hasClass('js-visible')){
                this.$el.scrollex({
                    top: '50%',
                    enter: function(){
                        $(this).addClass('u-visible');
                    }
                });
            }
        }
    }

    destroy() {
        // Removing event listeners
        // ==========================================================================
        super.destroy(isDebug, MODULE_NAME, EVENT_NAMESPACE);
    }
}