import { APP_NAME, isDebug } from '../utils/environment';
import AbstractModule from './AbstractModule';

const MODULE_NAME = 'Recaptcha';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);
        if(isDebug) console.log('🔨 [module]:constructor - '+MODULE_NAME);
        
        this.key        = this.el.getAttribute('data-key');
        this.csrfToken  = this.el.getAttribute('data-csrf-token');
    }

    init() {
        // new Promise((resolve, reject) => {
        //     if(typeof grecaptcha === 'undefined'){
        //             $.ajax({
        //                     url: `https://www.google.com/recaptcha/api.js?render=${this.key}`,
        //                     dataType: 'script',
        //                     success: (e)=>{ resolve(e); },
        //                     error: (e)=>{ reject(e); }
        //             });
        //     }else{ resolve(); }
        // })
        // .then(e => {
        //     grecaptcha.ready(()=>{
        //         grecaptcha.execute(this.key, { action: 'form' })
        //         .then((token) => {
        //             new Promise((resolve, reject) => {
        //                 $.ajax({
        //                     type: 'POST',
        //                     cache: false,
        //                     data: {
        //                         'token': token,
        //                         'CRAFT_CSRF_TOKEN': this.csrfToken
        //                     },
        //                     url: 'index.php/actions/recaptcha/verify/verify-token',
        //                     success: (response)=>{ resolve(JSON.parse(response)); },
        //                     error: (e)=>{ reject(e); }
        //                 });
        //             })
        //             .then((response)=>{
        //                 if(response.status === 200){
        //                     console.log('Use Verified');
        //                     // handle form ajax
        //                 }
        //                 else console.log(response.message);
        //             })
        //             .catch((error)=>{
        //                 console.log('Error:');
        //                 console.log(error);
        //             });
        //         });
        //     });
        // })
        // .catch(e => {
        //     console.log('Error: ', e);
        // });
    }

    destroy() {
        super.destroy(isDebug, MODULE_NAME, EVENT_NAMESPACE);
    }
}