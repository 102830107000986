/* jshint ignore:start */
import { APP_NAME, $document, $html, $body,  isDebug, $pjaxWrapper } from '../utils/environment';

const MODULE_NAME = 'Transition';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`,
    READYTOREMOVE: `readyToRemove.${EVENT_NAMESPACE}`,
    READYTODESTROY: `readyToDestroy.${EVENT_NAMESPACE}`
};

export default class {
    constructor(options) {
        this.options = options;
        this.wrapper = options.wrapper;
        this.overrideClass = options.overrideClass ? options.overrideClass : '';
        this.clickedLink = options.clickedLink;
        this.transitionWrapper = $('.js-page-transition');
    }

    launch() {
        if(isDebug) {
            console.log('---- Launch transition 👊 -----');
        }

        // Start page tranisition animation
        this.transitionWrapper.addClass('u-visible');

        $html
            .removeClass('dom-is-loaded dom-is-animated ')
            .addClass(`dom-is-loading ${this.overrideClass}`);

    }

    /*
    *   @param  {js dom element}
    *   @param  {js dom element}
    */
    hideView(oldView, newView) {
        let templateName = (oldView.childNodes[0].getAttribute('data-template') !== null) ? oldView.childNodes[0].getAttribute('data-template') : 'missing template data';
        
        if(isDebug) {
            console.log('----- ❌ [VIEW]:hide - ', templateName);
        }

        setTimeout(()=>{
            // launch it at the end (animations...)
            $document.triggerHandler({
                type:EVENT.READYTOREMOVE,
                oldView: oldView,
                newView: newView
            });
        }, 600);
    }


    displayView(view) {

        let templateName = (view.childNodes[0].getAttribute('data-template') !== null) ? view.childNodes[0].getAttribute('data-template') : 'missing template data';

        if(isDebug) {
            console.log('----- ✅ [VIEW]:display :', templateName);
        }

        $html.attr('data-template', templateName);

        // Finish page transition animation
        this.transitionWrapper.removeClass('u-visible');

        setTimeout(() => {
            $html
                .addClass('dom-is-loaded')
                .removeClass('dom-is-loading');
            $html
                .removeClass(this.overrideClass)
                .addClass('dom-is-animated');
            // launch it at the end (animations...)
            $document.triggerHandler({
                type:EVENT.READYTODESTROY
            });
        }, 600);

    }

    
    destroy() {
        if(isDebug) {
            console.log('---- ❌ [transition]:destroy -----');
        }
    }
}
