import { APP_NAME, isDebug, requestAnimFrame } from '../utils/environment';
import AbstractModule from './AbstractModule';

const MODULE_NAME = 'Alert';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);

        // Declaration of properties
        // ==========================================================================
        if(isDebug) console.log('🔨 [module]:constructor - '+MODULE_NAME);
        this.alerts             = this.$el.find('.js-alert');
        this.$loadingBar        = this.$el.find('.js-loading-bar');
        this.time               = null;
        this.loop               = ()=>{};
        this.transitionLength   = 0.6;
        this.basedTime          = 12 + this.transitionLength;
        this.countdown          = this.basedTime - this.transitionLength;
        this.isDirty            = false;
        this.isDebug            = false;
        this.currAlert          = 1;
        this.clearingAnim       = null;
        this.loadingAnim        = null;
    }

    init() {
        // Declaration of functions
        // ==========================================================================
        
        // clearLoading pauses the current loading animation and clears the bar
        // also handles the alert swap based on the input direction
        // only runs if we're not dirty
        // @param { direction: int }
        let clearLoading = (direction = 1)=>{
            if(this.isDirty) return;
            this.loadingAnim.pause();
            this.clearingAnim = anime({
                targets: this.$loadingBar[0],
                loop: false,
                duration: 600,
                delay: 0,
                easing: [.39,.57,.28,.99],
                translateX: '100%'
            });
            handleAlertSwap(direction);
        }

        // displayLoading uses animejs to display
        // a countdown timer for the mobile alerts section
        // @source http://animejs.com/documentation
        let displayLoading = ()=>{
            this.loadingAnim = anime({
                targets: this.$loadingBar[0],
                loop: false,
                translateX: [
                    { value: ['-100%', 0], duration: 12000, delay: 0, easing: 'linear' },
                    { value: '100%', duration: 600, delay: 0, easing: [.39,.57,.28,.99] }
                ]
            });
        }

        // cleanAlerts resets our dirty status
        // it also restarts our loading animation
        let cleanAlerts = ()=>{
            this.isDirty = false;
            displayLoading();
        }

        // handleAlertSwap uses animejs to transition between alerts
        // By default we are moving forward
        // Users can input a custom direction (reverse) or a specifID by click on the carousel controls
        // @source http://animejs.com/documentation
        // @param { direction : int }
        // @param { specificID : int }
        let handleAlertSwap = (direction = 1, specificID = null)=>{
            if(this.isDebug){
                console.log('%c =============================================', 'color: #87ff87');
                console.log('%c Switching Alerts', 'color: #ff7139');
            }

            // Get our incoming alert id
            // Handle out of bounds cases
            let incomingID = (specificID !== null) ? specificID : (this.currAlert + direction);
            if(incomingID > this.alerts.length) incomingID = 1;
            else if(incomingID < 1) incomingID = this.alerts.length;

            let leavingOffsetValue = -100;
            let incomingOffsetValue = 100;
            if(direction === -1){
                leavingOffsetValue = 100;
                incomingOffsetValue = -100;
            }

            // Swap alerts
            let leavingAnimation = anime({
                targets: this.$el.find('.js-alert[data-alert="'+this.currAlert+'"]')[0],
                duration: 600,
                delay: 0,
                easing: [.39,.57,.28,.99],
                translateY: [0, leavingOffsetValue+'%']
            });

            let incomingAnimation = anime({
                targets: this.$el.find('.js-alert[data-alert="'+incomingID+'"]')[0],
                duration: 600,
                delay: 0,
                easing: [.39,.57,.28,.99],
                translateY: [incomingOffsetValue+'%', 0]
            });
            
            this.isDirty = true;
            this.countdown = this.basedTime;
            this.currAlert = incomingID;
        }

        // loop runs the alerts carousel for mobile based on deltaTime
        // Uses requestAnimationFrame for callback when the DOM is repainted
        // If the carousel is dirty we need to clean it after the transition is finsiehd
        // Ignore user input while carousel is dirty
        // @source https://developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame
        this.loop = ()=>{
            let timeNew   = Date.now();
            let deltaTime = (timeNew - this.time) / 1000;
            this.time     = timeNew;
            this.countdown -= deltaTime;

            // The carousel is dirty and the transition has finished
            if(this.isDirty && this.countdown <= (this.basedTime - this.transitionLength)) cleanAlerts();

            // Countdown is finished, if we're not dirty switch slides
            if(this.countdown <= 0 && !this.isDirty) handleAlertSwap();

            requestAnimFrame(this.loop);
        }

        // IIFE for invoking the carousel loop
        (()=>{
            this.time = Date.now();
            displayLoading();
            this.loop();
        })();

        // Declaration of event listeners
        // ==========================================================================
        this.$el.swipe({
            swipeUp: ()=>{ 
                clearLoading();
            },
            swipeDown: ()=>{ 
                clearLoading(-1);
            },
            swipeRight: ()=>{ 
                clearLoading(-1);
            },
            swipeLeft: ()=>{ 
                clearLoading();
            },
        });
    }

    destroy() {
        // Removing event listeners
        // ==========================================================================
        this.loop = ()=>{};
        super.destroy(isDebug, MODULE_NAME, EVENT_NAMESPACE);
    }
}