import { APP_NAME, isDebug } from '../utils/environment';
import AbstractModule from './AbstractModule';

const MODULE_NAME = 'Lightcase';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);

        // Declaration of properties
        // ==========================================================================
        if(isDebug) console.log('🔨 [module]:constructor - '+MODULE_NAME);
    }

    init() {
        // Declaration of functions
        // ==========================================================================
        
        // Declaration of event listeners
        // ==========================================================================
        
        // IIFE to setup the event listener for our images lightcase
        // @source http://cornel.bopp-art.com/lightcase/documentation/
        (()=>{
            this.$el.lightcase();
        })();
    }

    destroy() {
        // Removing event listeners
        // ==========================================================================
        super.destroy(isDebug, MODULE_NAME, EVENT_NAMESPACE);
    }
}