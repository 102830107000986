/* jshint ignore:start */
/*
*   Cookie writing and getting functions.
*   @link https://www.quirksmode.org/js/cookies.html
*/
/*
*   This funciton will write a cookie based on the params
*   @param {string: cookies name}
*   @param {value: value to set}
*   @param {days: number of days before the cookie expires}
*   @exmaple
*       setCookie('ppkcookie','testcookie',7);
*/
export const $setCookie = function setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

/*
*   This funciton will return a cookies value
*   @param {name: cookies name}
*   @exmaple
*       var x = getCookie('ppkcookie');
*/
export const $getCookie = function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

/*
*   This funciton will erase a cookie
*   @param {name: cookies name}
*   @exmaple
*       eraseCookie('ppkcookie');
*/
export const $eraseCookie = function eraseCookie(name) {   
    document.cookie = name+'=; Max-Age=-99999999;';  
}