import { APP_NAME, isDebug, $window } from '../utils/environment';
import AbstractModule from './AbstractModule';

const MODULE_NAME = 'Header';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);

        // Declaration of properties
        // ==========================================================================
        if(isDebug) console.log('🔨 [module]:constructor - '+MODULE_NAME);
        this.menuBtnEl              = $('.js-menu-btn');
        this.nav                    = $('.js-nav');
        this.navLinks               = $('.js-nav-links');
        this.mobileDropdown         = $('.js-dropdown-button');
        this.dropdownReturnBtnEl    = $('.js-dropdown-return');
        this.dropdowns              = $('.js-dropdown');
    }

    init() {
        // Declaration of functions
        // ==========================================================================
        
        // Reset the nav by removing the status classes
        // Also adds back our hidden utility class to the dropdowns
        let resetNav = ()=>{
            this.navLinks.removeClass('-show-dropdown');
            this.menuBtnEl.removeClass('cross');
            this.nav.removeClass('-open');
            this.dropdowns.each(function(){
                $(this).addClass('u-hidden');
            });
        }

        // Declaration of event listeners
        // ==========================================================================
        document.addEventListener('pjax:prefetchRequested',(e) => resetNav());
        document.addEventListener('pjax:loadingCached',(e) => resetNav());
        
        this.menuBtnEl.on('click', ()=>{
            this.menuBtnEl.toggleClass('cross');

            if(this.nav.hasClass('-open')){
                resetNav();
            }else{
                this.nav.addClass('-open');
            }
        });

        this.mobileDropdown.on('click', function(){
            let $parent = $(this).parent();
            let $dropdown = $parent.find('.js-dropdown');
            let $nav = $parent.parent();

            $dropdown.toggleClass('u-hidden');
            $nav.toggleClass('-show-dropdown');
        });

        this.dropdownReturnBtnEl.on('click', function(){
            let $dropdown = $(this).parent();
            let $parent = $dropdown.parent();
            let $nav = $parent.parent();

            $dropdown.toggleClass('u-hidden');
            $nav.toggleClass('-show-dropdown');
        });
    }

    destroy() {
        // Removing event listeners
        // ==========================================================================
        this.menuBtnEl.off('click');
        this.mobileDropdown.off('click');
        this.dropdownReturnBtnEl.off('click');
        super.destroy(isDebug, MODULE_NAME, EVENT_NAMESPACE);
    }
}