import { APP_NAME, $document, $html, isDebug, $pjaxWrapper, $window } from '../utils/environment';
import { EVENT as APP_EVENT } from '../App';

//List here all of your transitions
import * as transitions from './transitions';

const MODULE_NAME = 'Transition';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`,
    READYTOREMOVE: `readyToRemove.${EVENT_NAMESPACE}`,
    READYTODESTROY: `readyToDestroy.${EVENT_NAMESPACE}`
};

/*

@todo :

- ✅ get data-transition on clicked link -> launch() and add switch(){}
- ❌ add goto listener
- ❌ add newPageReady functon with google analytics send (maybe pjax do that?)
- ✅ add overrideClass system for all transitions
- ✅ add base class manager like old DefaultTransition (dom-is-loaded, dom-is-loading etc..)



======= SCHEMA =======

[] : listener
* : trigger event

[pjax:send] -> (transition) launch()

[pjax:switch] (= new view is loaded) -> (transition) hideView()-> hide animations & *readyToRemove

[readyToRemove] -> remove() -> delete modules
                            -> remove oldView from the DOM, and innerHTMl newView
                            -> display()

display() -> (transition) displayView() -> display animations & *readyToDestroy
          -> init new modules

[readyToRemove] -> reinit()

*/

export default class {
    constructor() {

        // jQuery ondomready
        $window.on('load',() => {
            this.load();
        });

        return;

        // Defaults to BaseTransition for Pjax, it doesn't have any transition effects/CSS by default
        this.transition = new transitions['BaseTransition']({
            wrapper: this.wrapper
        });

        /*
        ===== PJAX CONFIGURATION =====
        */

        this.containerClass = '.js-pjax-container';
        this.wrapperID = '.js-pjax-wrapper';
        this.noPjaxRequestClass = 'no-transition';
        this.wrapper = document.querySelector(this.wrapperID);

        this.options = {
            debug: false,
            cacheBust: false,
            elements: [`a:not(.${this.noPjaxRequestClass})`],
            selectors: ['title',`${this.containerClass}`],
            switches: {},
            prefetch: true
        };
        this.options.switches[this.containerClass] = (oldEl, newEl, options) => this.switch(oldEl, newEl, options)
        // this.pjax = new Pjax(this.options);

        /*
        ===== LISTENERS =====
        */

        if(!this.pjax.options.prefetch) document.addEventListener('pjax:send',(e) => this.send(e));
        else{
            document.addEventListener('pjax:prefetch',(e) => this.prefetch(e));
            document.addEventListener('pjax:prefetchRequested',(e) => this.prefetchRequested(e));
            document.addEventListener('pjax:loadingCached',(e) => this.loadingCached(e));
        }


        $document.on(EVENT.READYTOREMOVE,(event) => {
            this.remove(event.oldView, event.newView);
        });
        $document.on(EVENT.READYTODESTROY,(event) => {
            this.reinit();
        });
    }

    /**
     * (PJAX) Launch when pjax receive a request
     * get & manage data-transition,init
     * @param  {event}
     * @return void
     */
    launchRequest(e){
        if(isDebug) {
            console.log('---- Launch request -----');
        }

        let el,transition;

        if(e.triggerElement !== undefined) {

            el = e.triggerElement;

            transition = el.getAttribute('data-transition') ? el.getAttribute('data-transition') : 'BaseTransition';
            $html.attr('data-transition',transition);

        } else {
            transition = 'BaseTransition';
            el = document;
        }

        // options available : wrapper, overrideClass
        this.transition = new transitions[transition]({
            wrapper: this.wrapper,
            clickedLink: el
        });
    }

     /**
     * (PJAX) Launch when pjax receive a prefetch request
     * launch request
     * @param  {event}
     * @return void
     */
    prefetch(e){
        this.launchRequest(e);
    }

    /**
     * (PJAX) Launch when the prefetch request is loading but the user
     * confirmed that they want to transition to the requested page
     * begin page transition animation
     * @param  {event}
     * @return void
     */
    prefetchRequested(e){
        if(isDebug) {
            console.log('---- Prefetch requested -----');
        }

        this.transition.launch();
    }

     /**
     * (PJAX) Launch when the user wants to transition to a cached
     * page that we have already requested
     * begin page transition animation
     * @param  {event}
     * @return void
     */
    loadingCached(e){
        if(isDebug) {
            console.log('---- Loading cached request -----');
        }

        this.transition.launch();
    }

    /**
     * (PJAX) Launch when pjax receive a non-prefetch request
     * launch it and begin page transition animation
     * @param  {event}
     * @return void
     */
    send(e) {
        this.launchRequest(e);
        this.transition.launch();
    }

    /**
     * (PJAX) Launch when new page is loaded
     * @param  {js dom element},
     * @param  {js dom element}
     * @param  {options : pjax options}
     * @return void
     */
    switch(oldView, newView, options) {
        if(isDebug) {
            console.log('---- Next view loaded -----');
        }
        this.transition.hideView(oldView, newView);
    }

    /**
     * Launch when you trigger EVENT.READYTOREMOVE in your transition -> hideView(), at the end
     * after oldView hidden, delete modules and launch this.display()
     * @param  {js dom element},
     * @param  {js dom element}
     * @return void
     */
    remove(oldView, newView) {

        $document.triggerHandler({
            type: APP_EVENT.DELETE_SCOPED_MODULES,
            $scope: $pjaxWrapper
        });

        oldView.remove();

        this.display(newView);
    }

    /**
     * launch after this.remove()
     * @param  {js dom element},
     * @return void
     */
    display(view) {
        this.wrapper.innerHTML = view.outerHTML;

        // Fetch any inline script elements.
        // const scripts = view.querySelectorAll('script.js-inline');
        // if (scripts instanceof window.NodeList) {
        //     let i = 0;
        //     let len = scripts.length;
        //     for (; i < len; i++) {
        //         eval(scripts[i].innerHTML);
        //     }
        // }

        $document.triggerHandler({
            type: APP_EVENT.INIT_SCOPED_MODULES,
            isPjax: true
        });

        // Switches old elements with the new elements
        // @link https://github.com/MoOx/pjax
        this.pjax.onSwitch();

        this.transition.displayView(view);

    }

    /**
     * Launch when you trigger EVENT.READYTODESTROY in your transition -> displayView(), at the end
     * @return void
     */
    reinit() {
        this.transition.destroy();
        $html.attr('data-transition','');
        this.transition = new transitions['BaseTransition']({
            wrapper: this.wrapper
        });
    }

    /**
     * DOM is loaded
     *
     * @return {void}
     */
    load() {
        $html.addClass('dom-is-loaded');
        $html.removeClass('dom-is-loading');
        setTimeout(() => {
            $html.addClass('dom-is-animated');
        }, 150)
    }
}
