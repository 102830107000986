/* jshint ignore:start */
/*
*   This function will take text and slice up each #text node into a span
*   @params { object : jQuery Object }
*   @returns { HTML }
*   @exmaple
*   @exmaple $('js-example').html($wordWrapper($('js-example')));
*/
export const $wordWrapper = function wordWrapper(object){
    const $obj = object;

    const speed             = ($obj.data('speed') !== undefined) ? $obj.data('speed') : 0;
    const initialDelay      = ($obj.data('delay') !== undefined) ? $obj.data('delay') : 0;
    const isTransitionDelay = ($obj.data('transition') !== undefined) ? true : false;
    const isAnimationDelay  = ($obj.data('animation') !== undefined) ? true : false;
    const initialText       = $obj.text();

    if ($obj.data('wrapped') !== undefined) return initialText;
    else{
        $obj.data('wrapped', true);
    }

    let words = [];    
    words = initialText.split(/\s+/); // Splits at spaces
    
    let loopCount = 0;
    let html = '';
    for(let n of words){
        let newNode = '';
        let delayAmount = speed * loopCount + initialDelay;

        if (isTransitionDelay && !isAnimationDelay) newNode = '<span class="o-wrapper -word" style="transition-delay:'+delayAmount+'ms;">' + n + '</span>';
        else if (!isTransitionDelay && isAnimationDelay) newNode = '<span class="o-wrapper -word" style="animation-delay:'+delayAmount+'ms;">' + n + '</span>';
        else if (isTransitionDelay && isAnimationDelay) newNode = '<span class="o-wrapper -word" style="transition-delay:'+delayAmount+'ms;animation-delay:'+delayAmount+'ms;">' + n + '</span>';
        else newNode = '<span class="o-wrapper -word">' + n + '</span>';
        html += newNode;
        loopCount++;
    }
    return html;
}
/*
*   This function will take text and slice up each letter and wrap it into a span
*   @params { object : jQuery object }
*   @returns { split content as HTML }
*   @exmaple $('js-example').html($letterWrapper($('js-example')));
*/
export const $letterWrapper = function letterWrapper(object){
    const $obj = object;

    const speed             = ($obj.data('speed') !== undefined) ? $obj.data('speed') : 0;
    const initialDelay      = ($obj.data('delay') !== undefined) ? $obj.data('delay') : 0;
    const isTransitionDelay = ($obj.data('transition') !== undefined) ? true : false;
    const isAnimationDelay  = ($obj.data('animation') !== undefined) ? true : false;
    const initialText       = $obj.text();

    if ($obj.data('wrapped') !== undefined) return $obj.html();
    else{
        $obj.data('wrapped', true);
    }
    
    // Generate an array of words
    let words = [];
    words = initialText.split(/\s+/);// Splits at spaces

    // Split words into arrays of letters
    let wordArrays = [];
    for(let w of words){
        let splitWords = [];
        splitWords = w.split('');
        wordArrays.push(splitWords);
    }

    // Rebuild our HTML where words are wrapper in spans with a class of '-word'
    // and each letter is has a span wrapper of '-char'
    let html = '';
    let loopCount = 0;
    for(let x of wordArrays){ // Loops through our array of words
        let newWord = '';
        let newHTML = '';
        for(let y of x){ // Loops through our array of letters
            // Wrap each letter in a span with a class of '-char'
            let newLetter = '';
            let delayAmount = speed * loopCount + initialDelay;

            // Add timing upon request
            if (isTransitionDelay && !isAnimationDelay) newLetter = '<span class="o-wrapper -char" style="transition-delay:'+delayAmount+'ms;">'+y+'</span>';
            else if (!isTransitionDelay && isAnimationDelay) newLetter = '<span class="o-wrapper -char" style="animation-delay:'+delayAmount+'ms;">'+y+'</span>';
            else if (isTransitionDelay && isAnimationDelay) newLetter = '<span class="o-wrapper -char" style="transition-delay:'+delayAmount+'ms;animation-delay:'+delayAmount+'ms;">'+y+'</span>';
            else newLetter = '<span class="o-wrapper -char">'+y+'</span>';
            
            // Add our new HTML to our word
            newWord += newLetter;
            loopCount++;
        }
        newHTML = '<span class="o-wrapper -word">'+newWord+'</span>'; // Wrap our new word in a span with the class of '-word'
        html += newHTML; // Add the new HTML to our HTML that we will return
    }
    return html;
}