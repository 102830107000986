/* jshint ignore:start */
import { APP_NAME, $html, isDebug } from '../utils/environment';
import { $getCookie, $setCookie, $eraseCookie } from '../utils/cookies';
import { $wordWrapper, $letterWrapper } from '../utils/wrapper';
import AbstractModule from './AbstractModule';

const MODULE_NAME = 'Base';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);

        // Declaration of properties
        if(isDebug) console.log('🔨 [module]:constructor - '+MODULE_NAME);

    }

    init() {
        // Getting our initial cookies for the user
        let firstOfDay = $getCookie(APP_NAME+'FirstOfDay');
        let uniqueUser = $getCookie(APP_NAME+'UniqueUser');

        // If our cookies are not null we will erase them first
        // Then we set our cookies for future visits
        function setCookies(){
            if(firstOfDay !== null) $eraseCookie(APP_NAME+'FirstOfDay');
            if(uniqueUser !== null) $eraseCookie(APP_NAME+'UniqueUser');

            $setCookie(APP_NAME+'FirstOfDay', false, 1);
            $setCookie(APP_NAME+'UniqueUser', false, 14);
        }

        // Sets a `active-status` class to our DOM's HTML so we
        // dont play some initial CSS animations every time we
        // pjax load a new page
        function setActiveStatus(){
            $html.addClass('active-session');
        }

        // Checks if the user is a first time visitor of the day
        // Sets the correct class to our DOM's HTML
        //
        // Checks if the user is a unique visitor for the last two weeks
        // Sets the correct class to our DOM's HTML
        (() => {
            if(firstOfDay === null || firstOfDay === true){
                $html.addClass('visitor-first-of-today');
            }else{
                $html.addClass('visitor-returning');
            }

            if(uniqueUser === null || uniqueUser === true){
                $html.addClass('visitor-unique');
            }else{
                $html.addClass('visitor-not-unique');
            }

            setCookies();
            setTimeout(setActiveStatus(), 2000);
        })();

        $(window).on('scroll', function(){
            if($(this).scrollTop() >= window.innerHeight/2){
                $html.addClass('has-scrolled');
            }else{
                $html.removeClass('has-scrolled');
            }
        });

        $('.js-letter').each(function(){
            $(this).html($letterWrapper($(this)));
        });

        $('.js-word').each(function(){
            $(this).html($wordWrapper($(this)));
        });
    }

    destroy() {
        super.destroy(isDebug, MODULE_NAME, EVENT_NAMESPACE);
    }
}
