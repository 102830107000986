import { APP_NAME, isDebug, requestAnimFrame } from '../utils/environment';
import AbstractModule from './AbstractModule';

const MODULE_NAME = 'Slideshow';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);

        // Declaration of properties
        // ==========================================================================
        if(isDebug) console.log('🔨 [module]:constructor - '+MODULE_NAME);
        this.isDirty        = false;
        this.baseTime       = this.$el.data('speed');
        this.isManaul       = (this.baseTime === 0) ? true : false;
        this.transitionTime = 2; // How long the transition should take (in seconds)
        this.basedTime      = this.baseTime + this.transitionTime
        this.countdown      = this.baseTime - this.transitionTime; // Remove transition time for initial slide
        this.loop           = null;
        this.isDebug        = false;
        this.slides         = this.$el.find('.js-slide');
        this.currSlide      = 1;
        this.controls       = this.$el.find('.js-control');
        this.time           = null;
    }

    init() {
        // Declaration of functions
        // ==========================================================================

        // handleSlideAnimations uses animejs to transition between slides
        // @source http://animejs.com/documentation
        // @param { $exitingSlide : jQuery Object }
        // @param { $incomingSlide : jQuery Object }
        // @param { direction : int }
        let handleSlideAnimations = ($exitingSlide, $incomingSlide, direction)=>{

            $exitingSlide.removeClass('u-visible');

            // Sliding old slide image
            let exitBackground = anime({
                targets: $exitingSlide.find('.js-background')[0],
                easing: [.39,.57,.28,.99],
                duration: 1200,
                delay: 600,
                translateX: (45 * direction)+'%'
            });

            // Sliding old slide
            let exitSlide = anime({
                targets: $exitingSlide[0],
                loop: false,
                duration: 1200,
                delay: 600,
                easing: [.39,.57,.28,.99],
                translateX: [0, (-100 * direction)+'%']
            });

            // Sliding new slide
            let incomingSlide = anime({
                targets: $incomingSlide[0],
                loop: false,
                easing: [.39,.57,.28,.99],
                duration: 1200,
                delay: 600,
                translateX: [(100 * direction)+'%', 0]
            });

            // Sliding new slide image
            let incomingBackground = anime({
                targets: $incomingSlide.find('.js-background')[0],
                loop: false,
                easing: [.39,.57,.28,.99],
                duration: 1200,
                delay: 600,
                translateX: [(-45 * direction)+'%', 0],
                complete: ()=>{
                    $incomingSlide.addClass('u-visible');
                }
            });

        }

        // handleSlideChange swaps out the current slide with the incoming slide
        // By default we are moving forward
        // @param { direction : int }
        // @param { specificID : int }
        let handleSlideChange = (direction = 1)=>{
            if(this.isDirty) return;
            
            // Get our incoming slide value
            // Handle out of bounds cases
            let incomingID = (this.currSlide + direction);
            if(incomingID > this.slides.length) incomingID = 1;
            else if(incomingID < 1) incomingID = this.slides.length;

            if(this.isDebug){
                console.log('%c =============================================', 'color: #87ff87');
                console.log('%c Switching Slides', 'color: #ff7139');
                console.log('%c Exiting Slide: ' + this.currSlide + ' | Incoming Slide: ' + incomingID, 'color: #fff4e0');
            }

            // Get our incoming and exiting slide objects
            let $exitingSlide   = this.$el.find('.js-slide[data-slide="'+this.currSlide+'"]');
            let $incomingSlide  = this.$el.find('.js-slide[data-slide="'+incomingID+'"]');

            // Animate slide transitions
            handleSlideAnimations($exitingSlide, $incomingSlide, direction);

            // Update the carousel settings
            this.currSlide = incomingID;
            this.countdown = this.basedTime;
            this.isDirty = true;
        }

        // cleanSlides sets all non-current slides to the starting position
        // Resets the isDirty status back to false
        let cleanSlides = ()=>{
            if(this.isDebug) console.log('%c Cleaning Slides', 'color: #ff7139');            
            this.isDirty = false;
        }

        // loop runs the slideshow based on deltaTime
        // Uses requestAnimationFrame for callback when the DOM is repainted
        // If the slideshow is dirty we need to clean it after the transition is finsiehd
        // Ignore user input while slideshow is dirty
        // @source https://developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame
        this.loop = ()=>{
            let timeNew   = Date.now();
            let deltaTime = (timeNew - this.time) / 1000;
            this.time     = timeNew;

            if(document.hasFocus() && !this.isManual) this.countdown -= deltaTime;

            // The slideshow is dirty and the transition has finished
            if(this.isDirty && this.countdown <= (this.basedTime - this.transitionTime)) cleanSlides();

            // Countdown is finished, if we're not dirty switch slides
            if(this.countdown <= 0 && !this.isDirty) handleSlideChange();

            requestAnimFrame(this.loop);
        }

        // IIFE to stat our loop
        (()=>{
            this.time = Date.now();
            this.loop();
        })();
        
        // Declaration of event listeners
        // ==========================================================================
        this.controls.on('click', function(){
            let direction = $(this).data('direction');
            handleSlideChange(direction);
        });

        this.$el.swipe({
            swipeRight: ()=>{
                handleSlideChange(-1);
            },
            swipeLeft: ()=>{
                handleSlideChange(1);
            }
        });
    }

    destroy() {
        // Removing event listeners
        // ==========================================================================
        this.loop = ()=>{}; // Creates an empty function for the next loop callback so garbage collection can happen
        this.controls.off('click');
        super.destroy(isDebug, MODULE_NAME, EVENT_NAMESPACE);
    }
}