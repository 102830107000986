import { APP_NAME, isDebug } from '../utils/environment';
import AbstractModule from './AbstractModule';

const MODULE_NAME = 'ItemCarousel';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);

        // Declaration of properties
        // ==========================================================================
        if(isDebug) console.log('🔨 [module]:constructor - '+MODULE_NAME);
        this.carousel       = this.$el.find('.js-carousel');
        this.controls       = this.$el.find('.js-controls');
        this.leftInput      = this.$el.find('.js-control-left');
        this.rightInput     = this.$el.find('.js-control-right');
        this.items          = this.carousel.find('.js-item');
        this.maxItems       = this.items.length;
        this.currItem       = 1;
        this.isDirty        = false;
        this.timeout        = null;
    }

    init() {
        // Declaration of functions
        // ==========================================================================
        
        let clean = ()=>{
            this.isDirty = false;
        }

        // handleAnimation uses animejs to shift the gallery carousel cards into/out of view
        // @source http://animejs.com/documentation/
        // @param { newItemID : int }
        // @param { exitingItemID : int }
        let handleAnimation = (newItemID, exitingItemID, direction = 1)=>{
            
            let $elementToUncenter  = this.carousel.find('.js-item[data-item='+this.currItem+']');
            let $elementToCenter    = this.carousel.find('.js-item[data-item='+newItemID+']');
            let $elementToExit      = this.carousel.find('.js-item[data-item='+exitingItemID+']');
            let $elementToEnter     = this.carousel.find('.js-item[data-item='+(newItemID + direction)+']');

            $elementToUncenter.removeClass('u-visible');
            $elementToCenter.addClass('u-visible');

            // This is the new item to display in the center
            let centerAnimation = anime({
                targets: $elementToCenter[0],
                duration: 600,
                delay: 300,
                easing: 'easeInOutQuint',
                translateX: 0
            });

            // This item is leaving our view
            let uncenterAnimation = anime({
                targets: $elementToUncenter[0],
                duration: 600,
                delay: 300,
                easing: 'easeInOutQuint',
                translateX: (50 * direction * -1)+'%'
            });

            // This item is entering our view
            let enterAnimation = anime({
                targets: $elementToEnter[0],
                duration: 600,
                delay: 300,
                easing: 'easeInOutQuint',
                translateX: (50 * direction)+'%'
            });

            let exitAnimation = anime({
                targets: $elementToExit[0],
                duration: 600,
                delay: 300,
                easing: 'easeInOutQuint',
                translateX: (100 * direction * -1)+'%'
            });
        }

        // updateInputs will style the controls to appear disabled when needed
        let updateInputs = ()=>{
            if(this.currItem === this.maxItems) this.rightInput.addClass('u-disabled');
            else this.rightInput.removeClass('u-disabled');

            if(this.currItem === 1) this.leftInput.addClass('u-disabled');
            else this.leftInput.removeClass('u-disabled');
        }
        
        // handleInput takes a direction and if it's allowed we will shift the items
        // @param { direction : int }
        let handleInput = (direction = 1)=>{
            let newCurrItem = this.currItem + direction;

            if(newCurrItem > this.maxItems) updateInputs();
            else if(newCurrItem < 1) updateInputs();
            else if(!this.isDirty){
                // Input is allowed, handle switch
                let exitingItem = this.currItem - direction;
                
                // Handle our transition animation(s)
                handleAnimation(newCurrItem, exitingItem, direction);

                // Update carousel statuss
                this.currItem = newCurrItem;
                this.isDirty = true;

                // Updates inputs to check if we're at the carousel limits
                updateInputs();

                this.timeout = setTimeout(clean, 600);
            }
        }

        // Declaration of event listeners
        // ==========================================================================
        this.controls.on('click', function(){
            if(!$(this).hasClass('u-disabled')){
                let direction = $(this).data('direction');
                handleInput(direction);
            }
        });

        this.carousel.swipe({
            swipeRight: ()=>{
                handleInput(-1);
            },
            swipeLeft: ()=>{
                handleInput();
            }
        });
    }

    destroy() {
        // Removing event listeners
        // ==========================================================================
        this.controls.off('click');
        clearTimeout(this.timeout);
        super.destroy(isDebug, MODULE_NAME, EVENT_NAMESPACE);
    }
}