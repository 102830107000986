import { APP_NAME, isDebug } from '../utils/environment';
import AbstractModule from './AbstractModule';

const MODULE_NAME = 'Form';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);

        // Declaration of properties
        // ==========================================================================
        if(isDebug) console.log('🔨 [module]:constructor - '+MODULE_NAME);
        this.form           = $(this.el);
        this.textEl         = this.form.find('.js-text');
        this.animationEl    = this.form.find('.js-box');
        this.animation      = null;
        this.response       = null;

        this.key        = this.el.getAttribute('data-key');
        this.csrfToken  = this.el.getAttribute('data-csrf-token');
    }

    init() {
        this.form.on('submit', (e)=>{
            e.preventDefault();
            //Display our submission animation(s)
            this.textEl.text('Sending');
            this.form.addClass('-submitting');
            this.playAnimation();
            this.handleRecaptcha();
        });
    }

    resetForm(hardReset = false){
        if(hardReset) this.form[0].reset();
        this.form.removeClass('-finished -error -success');
        this.animation = anime({
            targets: this.animationEl[0],
            delay: 150,
            duration: 600,
            width: 10,
            height: 10,
            translateX: -16,
            translateY: 0,
            backgroundColor: 'rgb(245, 121, 83)'
        });
    }

    // Server & contact form accepted our POST data
    // Animate success to inform user using animejs
    // Hard reset form after animation finishes
    // @source http://animejs.com/documentation/
    handleSuccess(){
        this.textEl.text("Success!");
        this.form.addClass('-success');
        this.animation = anime({
            targets: this.animationEl[0],
            duration: 600,
            width: 120,
            height: 120,
            translateX: 0,
            translateY: -30,
            backgroundColor: 'rgb(83, 245, 110)',
            complete: ()=>{
                setTimeout(()=>{
                    this.resetForm(true);
                }, 5000);
            }
        });
    }

    // Server or contact form had an issue with our POST request/data
    // Animate the error to inform the user using animejs
    // Soft reset the form to the user can atempt to send again
    // @source http://animejs.com/documentation/
    handleError(){
        this.textEl.text("ERROR");
        this.form.addClass('-error');
        this.animation = anime({
            targets: this.animationEl[0],
            duration: 600,
            width: 120,
            height: 120,
            translateX: 0,
            translateY: -30,
            backgroundColor: 'rgb(245, 83, 83)',
            complete: ()=>{
                setTimeout(this.resetForm, 1200);
            }
        });
    }

    checkResponse(){
        if(this.response === 1) this.handleSuccess();
        else if(this.response === 0) this.handleError();
        else this.playAnimation();
    }

    // This method plays our submissions 'sending' animation
    // Uses animejs to control CSS
    // Upon completion we check for a response from the server
    // @source http://animejs.com/documentation/
    playAnimation(){
        this.animation = anime({
            targets: this.animationEl[0],
            delay: 150,
            translateX: [
                { value: -16, duration: 0, delay: 0, elasticity: 0 },
                { value: 98, duration: 1000, delay: 500, elasticity: 0 },
                { value: -16, duration: 1000, delay: 500, elasticity: 0 }
            ],
            scaleX: [
                { value: 4, duration: 100, delay: 500, easing: 'easeOutExpo' },
                { value: 1, duration: 900, elasticity: 300 },
                { value: 4, duration: 100, delay: 500, easing: 'easeOutExpo' },
                { value: 1, duration: 900, elasticity: 300 }
              ],
            complete: ()=>{
                this.checkResponse();
            }
        });
    }


    handleSubmit(){
        let data = this.form.serialize();

        //Send data to the contact form plugin
        $.ajax({
            method: 'POST',
            url: '/recaptcha/verify/verify-token',
            data: data,
            complete: (response) => {
                console.log(response);
                this.form.addClass('-finished').removeClass('-submitting');
                if(response.status === 302 || response.status === 301) this.response = 1;
                else this.response = 0;
            }
        });
    }

    handleRecaptcha(){
        grecaptcha.ready(() => {
            grecaptcha.execute(this.key, { 
                action: 'submit' 
            }).then((token) => {
                // Submit Form
                const tokenEl = this.form.find('#token');
                tokenEl.val(token);
                this.handleSubmit();
            });
        });
    }

    destroy() {
        // Removing event listeners
        // ==========================================================================
        this.form.off('submit');
        super.destroy(isDebug, MODULE_NAME, EVENT_NAMESPACE);
    }
}