let uid = 0;

/**
 * Abstract Module
 */
export default class {
    constructor(options) {
        this.$el = options.$el || null;
        this.el  = options.el  || null;

        // Generate a unique module identifier
        this.uid = 'm-' + uid++;
        // Use jQuery's data API to "store it in the DOM"
        this.$el.data('uid', this.uid);
    }

    init() {}

    destroy(isDebug, MODULE_NAME, EVENT_NAMESPACE) {
        this.$el.removeData('uid');
        this.$el.off(`.${EVENT_NAMESPACE}`);
        if(isDebug) console.log('❌ [module]:destroy - '+ MODULE_NAME);
    }
}