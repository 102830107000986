import { APP_NAME, isDebug, requestAnimFrame } from '../utils/environment';
import AbstractModule from './AbstractModule';

const MODULE_NAME = 'HomepageCarousel';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);

        // Declaration of properties
        // ==========================================================================
        if(isDebug) console.log('🔨 [module]:constructor - '+MODULE_NAME);
        this.carousel           = this.$el.find('.js-carousel');
        this.slides             = this.carousel.children('.js-slide');
        this.controls           = this.$el.find('.js-control');
        this.time               = null;
        this.transitionLength   = 2;
        this.basedTime          = this.$el.data('speed') + this.transitionLength;
        this.countdown          = this.basedTime - this.transitionLength;
        this.isDirty            = false;
        this.currSlide          = 1;
        this.loop               = ()=>{};
        this.isDebug            = false;
    }

    init() {
        // Declaration of functions
        // ==========================================================================

        // updateControls switches the '-active' status for our carousel controls
        // @param { newSlideID : int }
        let updateControls = (newSlideID)=>{
            this.controls.each(function(){
                $(this).removeClass('-active');
                if($(this).data('slide') === newSlideID) $(this).addClass('-active');
            });
        }

        // handleSlideAnimations uses animejs to transition between slides
        // @source http://animejs.com/documentation
        // @param { $exitingSlide : jQuery Object }
        // @param { $incomingSlide : jQuery Object }
        // @param { direction : int }
        let handleSlideAnimations = ($exitingSlide, $incomingSlide, direction)=>{
            
            
            // Hidding old content
            // Build an array based on what content is being used
            let exitContentNodes = [];
            if($exitingSlide.find('.js-headline').length) exitContentNodes.push($exitingSlide.find('.js-headline')[0]);
            if($exitingSlide.find('.js-subheadline').length) exitContentNodes.push($exitingSlide.find('.js-subheadline')[0]);
            if($exitingSlide.find('.js-image').length) exitContentNodes.push($exitingSlide.find('.js-image')[0]);
            if($exitingSlide.find('.js-button').length) exitContentNodes.push($exitingSlide.find('.js-button')[0]);
            let exitContent = anime({
                targets: exitContentNodes,
                loop: false,
                translateX: {
                    duration: 600,
                    easing: [.39,.57,.28,.99],
                    delay: function(el, i, l){ return i * 100 },
                    value: [0, (-100 * direction)+'px']
                },
                opacity: {
                    duration: 300,
                    easing: 'easeOutQuad',
                    delay: function(el, i, l){ return i * 100 },
                    value: [1, 0]
                }
            });

            // Sliding old slide image
            let exitBackground = anime({
                targets: $exitingSlide.find('.js-background')[0],
                easing: [.39,.57,.28,.99],
                duration: 1200,
                delay: 300,
                translateX: (45 * direction)+'vw'
            });

            // Sliding old slide
            let exitSlide = anime({
                targets: $exitingSlide[0],
                loop: false,
                duration: 1200,
                delay: 300,
                easing: [.39,.57,.28,.99],
                translateX: [0, (-100 * direction)+'vw']
            });

            // Sliding new slide
            let incomingSlide = anime({
                targets: $incomingSlide[0],
                loop: false,
                easing: [.39,.57,.28,.99],
                duration: 1200,
                delay: 300,
                translateX: [(100 * direction)+'vw', 0]
            });

            // Sliding new slide image
            let incomingBackground = anime({
                targets: $incomingSlide.find('.js-background')[0],
                loop: false,
                easing: [.39,.57,.28,.99],
                duration: 1200,
                delay: 300,
                translateX: [(-45 * direction)+'vw', 0]
            });

            // Showing new content
            // Build an array based on what content is being used
            let incomingContentNodes = [];
            if($incomingSlide.find('.js-headline').length) incomingContentNodes.push($incomingSlide.find('.js-headline')[0]);
            if($incomingSlide.find('.js-subheadline').length) incomingContentNodes.push($incomingSlide.find('.js-subheadline')[0]);
            if($incomingSlide.find('.js-image').length) incomingContentNodes.push($incomingSlide.find('.js-image')[0]);
            if($incomingSlide.find('.js-button').length) incomingContentNodes.push($incomingSlide.find('.js-button')[0]);
            let incomingContent = anime({
                targets: incomingContentNodes,
                loop: false,
                translateX: {
                    duration: 600,
                    easing: [.39,.57,.28,.99],
                    delay: function(el, i, l){ return i * 100 + 1200 },
                    value: [(100 * direction)+'px', 0]
                },
                opacity: {
                    duration: 300,
                    easing: 'easeInQuad',
                    delay: function(el, i, l){ return i * 100 + 1200 },
                    value: [0, 1]
                }
            });
        }

        // handleSlideChange swaps out the current slide with the incoming slide
        // By default we are moving forward
        // Users can input a custom direction (reverse) or a specifID by click on the carousel controls
        // @param { direction : int }
        // @param { specificID : int }
        let handleSlideChange = (direction = 1, specificID = null)=>{
            if(this.isDirty) return;
            
            // Get our incoming slide value
            // Handle out of bounds cases
            let incomingID = (specificID !== null) ? specificID : (this.currSlide + direction);
            if(incomingID > this.slides.length) incomingID = 1;
            else if(incomingID < 1) incomingID = this.slides.length;

            if(this.isDebug){
                console.log('%c =============================================', 'color: #87ff87');
                console.log('%c Switching Slides', 'color: #ff7139');
                console.log('%c Exiting Slide: ' + this.currSlide + ' | Incoming Slide: ' + incomingID, 'color: #fff4e0');
            }

            // Get our incoming and exiting slide objects
            let $exitingSlide   = this.carousel.find('.js-slide[data-slide="'+this.currSlide+'"]');
            let $incomingSlide  = this.carousel.find('.js-slide[data-slide="'+incomingID+'"]');

            // Animate slide transitions
            handleSlideAnimations($exitingSlide, $incomingSlide, direction);
            
            // Update controller to display new active slide
            updateControls(incomingID);

            // Update the carousel settings
            this.currSlide = incomingID;
            this.countdown = this.basedTime;
            this.isDirty = true;
        }

        // cleanSlides sets all non-current slides to the starting position
        // Resets the isDirty status back to false
        let cleanSlides = ()=>{
            if(this.isDebug) console.log('%c Cleaning Slides', 'color: #ff7139');            
            this.isDirty = false;
        }

        // loop runs the homepage hero carousel based on deltaTime
        // Uses requestAnimationFrame for callback when the DOM is repainted
        // If the carousel is dirty we need to clean it after the transition is finsiehd
        // Ignore user input while carousel is dirty
        // @source https://developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame
        this.loop = ()=>{
            let timeNew   = Date.now();
            let deltaTime = (timeNew - this.time) / 1000;
            this.time     = timeNew;

            if(document.hasFocus()) this.countdown -= deltaTime;

            // The carousel is dirty and the transition has finished
            if(this.isDirty && this.countdown <= (this.basedTime - this.transitionLength)) cleanSlides();

            // Countdown is finished, if we're not dirty switch slides
            if(this.countdown <= 0 && !this.isDirty) handleSlideChange();

            requestAnimFrame(this.loop);
        }

        // IIFE for invoking the carousel loop
        (()=>{
            this.time = Date.now();
            this.loop();
        })();
        
        // Declaration of event listeners
        // ==========================================================================
        this.controls.on('click', ($this)=>{
            handleSlideChange(1, $($this.currentTarget).data('slide'));
        });

        this.carousel.swipe({
            swipeRight: ()=>{ handleSlideChange(-1); },
            swipeLeft: ()=>{ handleSlideChange() }
        });
    }

    destroy() {
        // Removing event listeners
        // ==========================================================================
        this.loop = ()=>{}; // Creates an empty function for the next loop callback so garbage collection can happen
        this.controls.off('click');
        super.destroy(isDebug, MODULE_NAME, EVENT_NAMESPACE);
    }
}