/* jshint ignore:start */
import { APP_NAME, $document, $html, isDebug, $pjaxWrapper } from '../utils/environment';
import BaseTransition from './BaseTransition';

const MODULE_NAME = 'Transition';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`,
    READYTOREMOVE: `readyToRemove.${EVENT_NAMESPACE}`,
    READYTODISPLAY: `readyToDisplay.${EVENT_NAMESPACE}`,
    READYTODESTROY: `readyToDestroy.${EVENT_NAMESPACE}`
};


export default class extends BaseTransition{
    constructor(options) {
        super(options);

        this.overrideClass = '-custom-transition';//Adds this class to the DOM
    }

    /*
    * Add additional funcitons from app/scripts/transitions/BaseTransitions.js here
    * They will overwrite the BaseTransition versions, otherwise it will use BaseTransitions as a fallback
    * In the example below we use jQuery to fade the previous page before triggering the READYTOREMOVE event
    *
    *  @example
    *      @params oldView = pjax container to remove
    *      @params newView = pjax container to show
    *      hideView(oldView, newView) {
    *          $(oldView).fadeOut(function(){
    *              launch it at the end (animations...)
    *              $document.triggerHandler({
    *                  type:EVENT.READYTOREMOVE,
    *                  oldView: oldView,
    *                  newView: newView
    *              });
    *          });
    *      }
    */

}
